import clsx from 'clsx';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import ScrollTo from '../common/scroll-to';

const HamburgerMenu = () => {
  return <img src="/icons/nav_menu.svg" width="48" className="" alt="Menu" />;
};

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    fill="none"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="3"
    viewBox="0 0 48 48"
    className="mx-auto"
  >
    <path d="M36 12L12 36" />
    <path d="M12 12L36 36" />
  </svg>
);

const Navbar = ({ path }) => {
  const [show, setShow] = useState(false);
  const isHome = path.includes('home');

  const headerClass = clsx('fixed w-full z-10 bg-white top-0 shadow-lg py-3', {
    'h-full bg-opacity-95': show,
  });

  const navClass = clsx(
    'w-full h-full flex flex-col items-center justify-center text-center ',
    { hidden: !show }
  );

  const renderHomeScreenMenu = () => (
    <nav className={navClass}>
      <ScrollTo
        to="videos"
        onClick={() => setShow(false)}
        className="text-3xl mb-10 text-black hover:text-purple cursor-pointer hover:underline"
      >
        <h1 className="text-4xl md:text-5xl">Happy Birthday videos</h1>
      </ScrollTo>
      <ScrollTo
        to="game"
        onClick={() => setShow(false)}
        className="text-3xl mb-10 text-black hover:text-purple cursor-pointer hover:underline"
      >
        <h1 className="text-4xl md:text-5xl">A playful birthday</h1>
      </ScrollTo>
      <ScrollTo
        to="decade"
        onClick={() => setShow(false)}
        className="text-3xl mb-10 text-black hover:text-purple cursor-pointer hover:underline"
      >
        <h1 className="text-4xl md:text-5xl">Top tens of the decade</h1>
      </ScrollTo>
      <ScrollTo
        to="time"
        onClick={() => setShow(false)}
        className="text-3xl mb-10 text-black hover:text-purple cursor-pointer hover:underline"
      >
        <h1 className="text-4xl md:text-5xl">Trax through time</h1>
      </ScrollTo>
      <ScrollTo
        to="pioneering"
        onClick={() => setShow(false)}
        className="text-3xl mb-10 text-black hover:text-purple cursor-pointer hover:underline"
      >
        <h1 className="text-4xl md:text-5xl">Pioneering Traxers</h1>
      </ScrollTo>
    </nav>
  );

  return (
    <div>
      <header className={headerClass}>
        <div className="container w-full flex">
          <Link
            className="w-10/13 md:w-10/12 m-2 flex leading-normal"
            to="/home"
            onClick={() => setShow(false)}
          >
            <img
              src="/icons/trax-logo.svg"
              width="57"
              className="inline pr-3"
              alt="Trax Logo"
            />
            <p className="w-48 text-black text-base">
              Let’s celebrate Trax’s 10th birthday together!
            </p>
          </Link>
          {isHome ? (
            <button
              type="button"
              onClick={() => setShow(!show)}
              className="mx-auto mr-3 no-underline"
            >
              {show ? <CloseIcon /> : <HamburgerMenu />}
            </button>
          ) : (
            <div className="w-2/12 flex items-center">
              <Link
                className="text-orangepast border-b border-orangepast leading-6"
                to="/home"
              >
                <img
                  src="/icons/arrow_orange.svg"
                  width="20"
                  className="duration-200 inline pl-2 rotate-180 transform"
                  alt="back"
                />
                Back to Home
              </Link>
            </div>
          )}
        </div>
        {isHome && renderHomeScreenMenu()}
      </header>
    </div>
  );
};

export default Navbar;
