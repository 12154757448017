import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          foot: file(relativePath: { regex: "/foot_bg.jpg/" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1442) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={({ foot }) => (
        <BackgroundImage
          Tag="section"
          id="hero h-screen"
          fluid={foot.childImageSharp.fluid}
        >
          <div className="container text-center">
            <p className="mx-auto text-2xl p-5 uppercase text-white">Happy 10th Birthday Trax!</p>
          </div>
        </BackgroundImage>
      )}
    />
  );
};

export default Footer;

