import OktaSignIn from '@okta/okta-signin-widget';
import { navigate } from 'gatsby';
import TraxLogo from '../assets/okta.png';
import { OKTA_ISSUER, OKTA_CLIENT_ID } from '../config';

const config = {
  baseUrl: OKTA_ISSUER,
  clientId: OKTA_CLIENT_ID,
  logo: TraxLogo,
  redirectUri:
    typeof window !== 'undefined' && `https://www.decadeoftrax.com/home`,
  el: '#signIn',
  authParams: {
    pkce: true,
    responseType: ['token', 'id_token'],
  },
  features: {
    registration: true,
  },
};

export const isAuthenticated = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('isAuthenticated') === 'true';
  }
  return false;
};

export const signIn = typeof window !== 'undefined' && new OktaSignIn(config);

export const logout = (setUser) => {
  signIn.authClient
    .signOut()
    .catch((error) => {
      console.error(`Sign out error: ${error}`);
    })
    .then(() => {
      console.error(`Sign out SUCCESS`);
      localStorage.setItem('isAuthenticated', 'false');
      setUser(false);
      navigate('/');
    });
};
