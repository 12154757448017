import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { navigate } from 'gatsby';

import { isAuthenticated } from '../../utils';

import Navbar from './navbar';
import Footer from './footer';

const Layout = ({ children, path }) => {
  const withoutFrame = path.includes('login') || path === '/';

  if (typeof window === 'undefined') {
    console.log('Layout no window');
    return null;
  }

  if (!isAuthenticated()) {
    navigate('/login');
  }

  if (withoutFrame) {
    return <main>{children}</main>;
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title="Decade of Trax"
        meta={[]}
      />
      <Navbar path={path} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
