/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import Layout from './src/components/layout/layout';
import './src/style/index.css';

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
