module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Decade of Trax","short_name":"Decade of Trax","start_url":"/","background_color":"#592c82","theme_color":"#592c82","display":"standalone","icon":"assets/trax.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"4446babab858c04009c75ef557cf3bcd"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
